<template>

    <header>

        <!-- Skip Links als erste Elemente -->
        <div class="skip-links sr-only">
            <a href="#main-content" class="sr-only focus:not-sr-only focus:fixed focus:top-4 focus:left-4 bg-orange text-white p-4 z-50 rounded-md focus:outline-none focus:ring-2 focus:ring-orange focus:ring-offset-2">
                Zum Hauptinhalt springen
            </a>
            <a href="/kontakt" class="sr-only focus:not-sr-only focus:fixed focus:top-4 focus:left-48 bg-orange text-white p-4 z-50 rounded-md focus:outline-none focus:ring-2 focus:ring-orange focus:ring-offset-2">
                Zum Kontaktformular springen
            </a>
        </div>

        <div ref="divRef" id="divRef" class="fixed top-0 md:shadow-xl inset-x-0 z-50 bg-erieblack bg-opacity-0 md:bg-opacity-100 md:backdrop-blur-sm">
            <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div class=" hidden md:flex md:flex-1 md:justify-center">
                    <a v-for="(item, index) in navigation" :key="item.name" :href="item.href" class="flex items-center text-base font-semibold leading-6 text-perlwhite-500 uppercase ml-3 scroller hover:no-underline hover:text-orange-500">
                        <span>{{ item.name }}</span>
                        <div v-if="index < navigation.length - 1" class=" h-5 ml-3"></div>
                    </a>
                </div>
            </nav>
        </div>

        <nav class="bg-erieblack h-16 z-50 fixed w-full shadow-xl flex justify-between md:hidden" aria-label="Global" ref="navilogo_mobile" id="navilogo_mobile">

            <div class="w-14 relative  md:hidden left-6 top-4 z-50">
                <a href="/"><img :src="logo_small.src" alt="Tobeworks Logo klein" /></a>
            </div>

            <div class="w-10 h-10 relative  md:hidden top-4 right-5 z-50 cursor-pointer" ref="navibutton_mobile" id="navibutton_mobile">
                <button class="ring-0 border-0 text-orange-500" @click="mobileMenuOpen = true" aria-label="Navigation-Button">
                    <svg viewBox="0 0 16 16" fill="currentColor" class="bi bi-list text-light w-full h-full" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                    </svg>
                </button>
            </div>
        </nav>

        <TransitionRoot :show="mobileMenuOpen" as="template">
            <Dialog as="div" class="lg:hidden">
                <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-900/80" />
                </TransitionChild>
                <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-y-full" enter-to="translate-y-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-y-0" leave-to="-translate-y-full">

                    <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-orange-500 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div class="w-10 h-10 absolute md:hidden top-6 right-6">
                            <button @click="mobileMenuOpen = false" class="mb-4 inline-flex items-center justify-center p-2 rounded-full text-erieblack hover:text-erieback-500 ring-0 border-0">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </button>
                        </div>
                        <div>
                            <img :src="logo_erieblack.src" alt="Tobeworks Logo" class="mx-auto" data-aos="fade-in" />
                        </div>
                        <div class="flex flex-col items-center justify-center text-center h-screen">
                            <div class="-my-6 divide-y divide-gray-500/10">
                                <div class="py-6">
                                    <a v-for="item in navigation" :key="item.name" :href="item.href" class="scroller -mx-3 block rounded-lg px-3 py-2 font-semibold text-erieblack-500 hover:text-erieblack-300 uppercase text-lg" @click="mobileMenuOpen = false">{{ item.name }}</a>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </Dialog>
        </TransitionRoot>
    </header>
</template>

<script setup>
import { ref, onMounted, computed, onBeforeMount } from 'vue'
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue'
import { gsap } from "gsap";
import logo_small from "../assets/logo_small.svg";
import logo_erieblack from "../assets/images/logos/TW-erieblack.svg";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const divRef = ref(null);
const navibutton_mobile = ref(null);
const navilogo_mobile = ref(null);

const mobileMenuOpen = ref(false)
const prefix = ref('')

const navigation = computed(() => [
    { name: 'Home', href: `/` },
    { name: 'About', href: `/about-webentwicklung-freelancer-mainz-rheinmain/` },
    { name: 'Insights', href: `/insights/` },
    { name: 'Projekte', href: `/projekte/` },
    { name: 'Kontakt', href: `/kontakt/` },
])

onBeforeMount(() => {
    const tl = gsap.timeline();
    tl.set('#divRef', { opacity: 0 });
    tl.set('#navibutton_mobile', { opacity: 0 });
    tl.set('#navilogo_mobile', { opacity: 0 });
})

onMounted(() => {

    // const tl = gsap.timeline();
    // tl.set('#divRef', { opacity: 0});

    const currentPath = window.location.pathname;

    if (currentPath !== '/') {
        prefix.value = '/';
    }
    else {
        prefix.value = ''
    }
    gsap.set(divRef.value, { opacity: 0, y: -135 });
    gsap.set(navibutton_mobile.value, { opacity: 0, y: -135 });

    gsap.to(divRef.value, {
        opacity: 1,
        y: 0,
        scrollTrigger: {
            trigger: '#start-container',
            start: "bottom center",
            end: "center center",
            //scrub: true,
            markers: false,
            toggleActions: "restart none none reverse",
        },
    });

    gsap.to(navibutton_mobile.value, {
        opacity: 1,
        y: 0,
        scrollTrigger: {
            trigger: '#start-container',
            start: "bottom center",
            end: "center center",
            //scrub: true,
            markers: false,
            toggleActions: "restart none none reverse",
        },
    });

    gsap.to(navilogo_mobile.value, {
        opacity: 1,
        y: 0,
        scrollTrigger: {
            trigger: '#start-container',
            start: "bottom center",
            end: "center center",
            //scrub: true,
            markers: false,
            toggleActions: "restart none none reverse",
        },
    });
})


</script>

<style>
#divRef, #navibutton_mobile, #navilogo_mobile {
    opacity: 0;
}
</style>